import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Mid of Q2 2023",
      title: "Concept",
      info: [
        "Proof of concept",
        "Seed initial phase",
        "Assembling team",
      ],
    },
    {
      roadmapTitle: "Early Q4 2023",
      title: "Initial market launch",
      info: [
        "Start smartcontracts development",
        "Small whitelist",
        "Monthly profit distribution",
      ],
    },
    {
      roadmapTitle: "Mid of Q1 2024",
      title: "Partnerships and Expansions",
      info: [
        "Establish strategic partnerships",
        "Expand into new international markets",
        "Improve operational efficiency",
      ],
    },

    {
      roadmapTitle: "Mid of Q2 2024",
      title: "Engagement and Governance",
      info: [
        "Strengthen community engagement",
        "Establish a governance framework",
        "Open new opportunities for growth",
      ],
    },
    {
      roadmapTitle: "Mid of Q3 2024",
      title: "AI Integration for Analytics",
      info: [
        "Data Analysis Enhancement",
        "Finalise research for other chains",
        "Predictive Analytics Module",
      ],
    },
    {
      roadmapTitle: "End of Q4 2024",
      title: "Third-Party Integrations",
      info: [
        "Cryptocurrency Integration",
        "Partnership with External Services",
        "API Development for Integration",
      ],
    },
    {
      roadmapTitle: "Begin of Q1 2025",
      title: "Operational Efficiency Improvements",
      info: [
        "Process Optimization",
        "Automation Implementation",
        "Knowledge Base",
      ],
    },
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">Our Roadmap</span>
              <h2 className="title">
                Credit Ledger <br /> Project <span>Plan</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              ref={scrollRef}
              style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
