import React from "react";
import FooterOne from "../components/Footer/FooterOne";
import HeaderOne from "../components/Header/HeaderOne";
import PageLoader from "../components/PageLoader/PageLoader";
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import cn from "classnames";

const LayoutOne = (props) => {
  React.useEffect(()=>{
    try {
      (function (w, d, s, o, f, js, fjs) {
        w["botsonic_widget"] = o;
        w[o] =
          w[o] ||
          function () {
            (w[o].q = w[o].q || []).push(arguments);
          };
        (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
        js.id = o;
        js.src = f;
        js.async = 1;
        fjs.parentNode.insertBefore(js, fjs);
      })(window, document, "script", "Botsonic", "https://widget.writesonic.com/CDN/botsonic.min.js")
      Botsonic("init", {
        serviceBaseUrl: "https://api.botsonic.ai",
        token: "bb2d6868-c464-4c25-aa3d-23f28a5c68c6",
      });
    } catch (error) {
      
    }
  } , []);

  return (
    <div className={cn("")}>
      <PageLoader />

      <HeaderOne />

      {props.children}

      <FooterOne />
      <Analytics />
      <SpeedInsights/>
    </div>
  );
};

export default LayoutOne;
