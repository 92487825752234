import React from "react";
import { Link } from "react-router-dom";
// import docImg from "../../assets/img/images/document_img.png";

const docImg = "https://ik.imagekit.io/abkvohwfl/platform/Asset%20Token%20Backed%20no-bg_b7tDFrLgq.png";

const WhitePaper = () => {

  const handleBuyClick = () => {
    try {
      window.dataLayer.push({
        event: 'buy',
        eventCategory: 'whitepaper',
        eventAction: 'whitepaper',
        eventLabel: 'whitepaper',
      });
      if (window && typeof window !== undefined) {
        const win = window.open('https://presale.creditledger.xyz?utm_source=web3_app', '_blank');
        win?.focus();
      }
    } catch (error) { }
  }

  return (
    <section className="document-area pt-60">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7 order-2 order-lg-0">
            <div
              className="document-img text-center wow fadeInUp"
              data-wow-delay=".2s"
            >
              <img src={docImg} alt="" width={400} />
            </div>
          </div>

          <div className="col-lg-5 col-md-7">
            <div
              className="document-content mt-50 wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title mb-35">
                <span className="sub-title">
                  Short-term receivables
                </span>
                <h2 className="title">
                  First one <br /><span>Receivables token</span>
                </h2>
              </div>

              <ul className="document-list">
                <li>
                  No hidden fees
                </li>
                <li>
                  Privacy and profitability
                </li>
                <li>
                  Constant distribution of profits
                </li>
                <li>
                  Token backed by real collateral
                </li>
              </ul>

              <Link to="/" className="btn" onClick={handleBuyClick}>
                Purchase now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhitePaper;
