import React from "react";
import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { createClient } from "@supabase/supabase-js";
import { supabaseClientToken, supabaseBaseUrl } from "../../lib/constants";

const supabase = createClient(supabaseBaseUrl, supabaseClientToken);
    
const date = "12-01-2023";

const CountDownOne = () => {
  const [configs, setConfigs] = useState({});

  async function getConfigs() {
    let { data: config, error } = await supabase.from('config').select('*');
    if (error) return;
    setConfigs(config[0]);
  }

  useEffect(() => {
    getConfigs();
  }, []);
  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <>
          <div className="time-count day">
            <span>{"00"}</span>Days
          </div>
          <div className="time-count hour">
            <span>{"00"}</span>hour
          </div>
          <div className="time-count min">
            <span>{"00"}</span>minute
          </div>
          <div className="time-count sec">
            <span>{"00"}</span>second
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="time-count day">
            <span>{days < 10 ? "0" + days : days}</span>Days
          </div>
          <div className="time-count hour">
            <span>{hours < 10 ? "0" + hours : hours}</span>hour
          </div>
          <div className="time-count min">
            <span>{minutes < 10 ? "0" + minutes : minutes}</span>minute
          </div>
          <div className="time-count sec">
            <span>{seconds < 10 ? "0" + seconds : seconds}</span>second
          </div>
        </>
      );
    }
  };

  return (
    <div className="coming-time">
      <Countdown date={configs && configs.coutdownDate ? new Date(configs.coutdownDate) : new Date(date)} renderer={renderer} />
    </div>
  );
};

export default CountDownOne;
